import { createSlice } from '@reduxjs/toolkit'

interface CounterState {
  walletInput: any
}

const initialState = { walletInput: undefined } as CounterState

const inputAmountSlice = createSlice({
  name: 'inputAmount',
  initialState,
  reducers: {
    setWalletInput(state, action) {
      state.walletInput = action.payload
    }
  }
})

export const { setWalletInput } = inputAmountSlice.actions
export default inputAmountSlice.reducer
