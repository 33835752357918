import { createSlice } from '@reduxjs/toolkit'
import { CryptoModel } from 'models/reduxModels/cryptoModel'

interface CounterState {
  listSupportedCrypto: CryptoModel[]
}

const initialState = { listSupportedCrypto: [] } as CounterState

const listSupportedCryptoSlice = createSlice({
  name: 'listSupportedCrypto',
  initialState,
  reducers: {
    setListSupportedCrypto(state, action) {
      state.listSupportedCrypto = action.payload
    }
  }
})

export const { setListSupportedCrypto } = listSupportedCryptoSlice.actions
export default listSupportedCryptoSlice.reducer
