import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'
import React, { useCallback, useState } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { store } from 'redux/store'
import { WagmiConfig, configureChains, createConfig, mainnet, sepolia } from 'wagmi'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { publicProvider } from 'wagmi/providers/public'
import { changePrimaryColor } from './components/changePrimaryColor'
import AuthenticationProvider from './routes/authProvider'
import RouterComponent from './shade'

const NET_CHAIN = process.env.REACT_APP_CHAIN_ENV
const REACT_APP_RPC_URL: any = process.env.REACT_APP_RPC_URL

export const useEvenBus = () => {
  const [notificationEvent, setNotificationEvent] = useState<any>()

  const handleNotificationEvent = useCallback(
    (notificationKey?: any, productId?: any, walletType?: any) =>
      setNotificationEvent({ notificationKey: notificationKey, productId: productId, walletType: walletType }),
    []
  )

  return {
    notificationEvent,
    handleNotificationEvent
  }
}

const App: React.FC = () => {
  changePrimaryColor('#038757')

  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [NET_CHAIN === 'mainnet' ? mainnet : sepolia],
    [
      NET_CHAIN === 'mainnet'
        ? jsonRpcProvider({
            rpc: (chain) => ({
              http: REACT_APP_RPC_URL
            })
          })
        : publicProvider()
    ]
  )

  // Set up wagmi config
  const config = createConfig({
    autoConnect: false,
    connectors: [
      new MetaMaskConnector({
        chains,
        options: {
          UNSTABLE_shimOnConnectSelectAccount: true,
          shimDisconnect: true
        }
      })
    ],
    // connectors: [
    //   new InjectedConnector({
    //     chains: [NET_CHAIN === 'mainnet' ? mainnet : sepolia],
    //     options: {
    //       name: 'MetaMask',
    //       getProvider: () => {
    //         console.log((window as any).ethereum.isMetaMask)
    //         return typeof window !== 'undefined' ? (window as any).ethereum : undefined
    //       }
    //     }
    //   })
    // ],
    publicClient,
    webSocketPublicClient
  })

  // const event = React.useSelector((state: IApplicationState) => state.listNotificationFirebase.newNotifications)
  // const options: any = {
  //   autoClose: 3000,
  //   pauseOnFocusLoss: true,
  //   hideProgressBar: true,
  //   position: 'bottom-right',
  //   pauseOnHover: true
  //   // and so on ...
  // }

  // const renderHtml = (text: string) => {
  //   return <div dangerouslySetInnerHTML={{ __html: text }}></div>
  // }

  // React.useEffect(() => {
  //   getFireBaseToken()
  //   const channel = new BroadcastChannel('notifications')
  //   channel.addEventListener('message', (event) => {
  //     handleMessageFireBase(event.data)
  //   })
  // }, [])

  // const requestNotificationPermission = async () => {
  //   await Notification.requestPermission()
  // }

  // React.useEffect(() => {
  //   onMessage(messaging, (payload) => {
  //     handleMessageFireBase(payload, true)
  //   })
  // }, [event])

  // const handleMessageFireBase = (payload: any, isForeground?: boolean) => {
  //   const { data } = payload
  //   try {
  //     if (data?.event === 'userStatusDeactive') {
  //       deactiveUser()
  //     }
  //     const object = data ? JSON.parse(data?.dto) : ''
  //     const title = object.title
  //     const content = object.html
  //     const Msg = () => {
  //       return (
  //         <>
  //           <div>{title}</div>
  //           <div>{renderHtml(content)}</div>
  //         </>
  //       )
  //     }
  //     if (title !== undefined && content !== undefined) {
  //       if (isForeground) {
  //         toast.success(<Msg />, options)
  //       }
  //       dispatch(saveNewNotification(object))
  //     }
  //     if (data) {
  //       dispatch(handleNotiRefreshData(object))
  //     }
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // const getFireBaseToken = () => {
  //   if (Notification.permission === 'granted') {
  //     getToken(messaging, {
  //       vapidKey: 'BOdbArERgAYHwYd7oFLxWDukwneiBS9aNXjPJL-FNwxOTRjVCki-FLnDtmeX-Gwhg9nNoHjdzBn49xwDyeBqPaY'
  //     })
  //       .then((currentToken) => {
  //         if (currentToken) {
  //           // Send the token to your server and update the UI if necessary
  //           // ...
  //           setStorageItem('firebase-token', currentToken)
  //         } else {
  //           // Show permission request UI
  //           console.log('No registration token available. Request permission to generate one.')
  //           // ...
  //         }
  //       })
  //       .catch((err: any) => {
  //         console.log('An error occurred while retrieving token. ', err)
  //         // ...
  //       })
  //   }
  // }

  // React.useCallback(() => {
  //   requestNotificationPermission()
  // }, [])

  return (
    <AuthenticationProvider>
      <WagmiConfig config={config}>
        <Provider store={store}>
          <RouterComponent />
          <ToastContainer
            position='top-right'
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={1}
            className='max-z-index'
          />
        </Provider>
      </WagmiConfig>
    </AuthenticationProvider>
  )
}

export default App
