import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import translationEN from '../locales/en/translation.json'
import translationKO from '../locales/ko/translation.json'
import { getStorageItem } from 'utils/localStorageHelper'
import { LocalStorageKey } from 'constants/localStorageKey'

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ko: {
    translation: translationKO
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false, // <-- disable default console.log
    resources,
    fallbackLng: getStorageItem(LocalStorageKey.WebLanguage) || 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
