import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { LocalStorageKey } from 'constants/localStorageKey'
import { ApiReducerPath, ApiTagTypes } from 'constants/service'
import { clearStorageItems, getStorageItem, setStorageItem } from 'utils/localStorageHelper'
import { toastError } from 'utils/toastHelper'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    const userToken = getStorageItem(LocalStorageKey.UserToken)
    if (userToken) {
      headers.set('Authorization', `Bearer ${userToken?.accessToken}`)
    }
    return headers
  },
  timeout: 10000
})

const mutex = new Mutex()

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const userToken = getStorageItem(LocalStorageKey.UserToken)
        const body = {
          refresh_token: userToken.refreshToken
        }
        const refreshResult = await baseQuery(
          {
            method: 'POST',
            url: '/auth/refresh-token',
            body
          },
          api,
          extraOptions
        )

        if (
          typeof refreshResult?.data === 'object' &&
          refreshResult?.data !== null &&
          'status' in refreshResult?.data &&
          refreshResult?.data?.status === 200 &&
          'data' in refreshResult?.data
        ) {
          setStorageItem(LocalStorageKey.UserToken, JSON.stringify(refreshResult?.data?.data))
          result = await baseQuery(args, api, extraOptions)
        } else {
          try {
            await baseQuery(
              {
                method: 'POST',
                url: 'auth/logout'
              },
              api,
              extraOptions
            )
            clearStorageItems()
            toastError('session_expired_label', 'session_expired_label')
            setTimeout(() => window?.location.replace(`/auth/login`), 3000)
          } catch (error) {}
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}

// const baseQueryWithRetry = retry(baseQueryWithReauth, { maxRetries: 1 })

export const api = createApi({
  reducerPath: ApiReducerPath,
  baseQuery: baseQueryWithReauth,
  tagTypes: Object.keys(ApiTagTypes),
  endpoints: () => ({})
})
