import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Auth from '../pages/auth/auth'
import Loader from './Loaders/Loaders'

const RouterComponent: React.FC = () => {
  const App = React.lazy(() => import('./layouts/App'))

  const Login = React.lazy(() => import('../pages/auth/login/index'))
  const VerifyOtp = React.lazy(() => import('../pages/auth/verifyOTP/index'))

  const Dashboard = React.lazy(() => import('../pages/dashboard/index'))
  const WalletManagement = React.lazy(() => import('../pages/walletManagement/index'))
  const Transaction = React.lazy(() => import('../pages/transaction/index'))
  const Commission = React.lazy(() => import('../pages/setting/commission'))
  const AdminAccount = React.lazy(() => import('../pages/setting/adminAccount'))
  const Product = React.lazy(() => import('../pages/setting/productManagement'))
  const ProductRecordHistory = React.lazy(() => import('../pages/setting/productManagement/recordHistory'))
  const TokenManagement = React.lazy(() => import('../pages/setting/tokenManagement'))
  const DailyWithdrawalLimitation = React.lazy(() => import('../pages/setting/withDrawLimitation'))
  const WalletThresholds = React.lazy(() => import('../pages/setting/walletThreshold'))
  const WalletDetail = React.lazy(() => import('../pages/walletManagement/walletDetail/index'))
  const TopupRequestHistory = React.lazy(() => import('../pages/walletManagement/topupRequestHistory/index'))
  const HolaCoin = React.lazy(() => import('../pages/holaCoin/index'))
  const Client = React.lazy(() => import('../pages/setting/clientManagement'))

  return (
    <React.Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
              <Route index element={<Login />} />
              <Route path={`${process.env.PUBLIC_URL}/auth/login`} element={<Login />} />
              <Route path={`${process.env.PUBLIC_URL}/auth/verify-otp`} element={<VerifyOtp />} />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
              <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<Dashboard />} />
              <Route path={`${process.env.PUBLIC_URL}/wallet-management`} element={<WalletManagement />} />
              <Route path={`${process.env.PUBLIC_URL}/transaction/:transactionType`} element={<Transaction />} />
              <Route path={`${process.env.PUBLIC_URL}/setting/commission`} element={<Commission />} />
              <Route path={`${process.env.PUBLIC_URL}/setting/admin-account`} element={<AdminAccount />} />
              <Route path={`${process.env.PUBLIC_URL}/setting/product`} element={<Product />} />
              <Route path={`${process.env.PUBLIC_URL}/setting/client`} element={<Client />} />
              <Route
                path={`${process.env.PUBLIC_URL}/setting/product/record-history`}
                element={<ProductRecordHistory />}
              />
              <Route path={`${process.env.PUBLIC_URL}/setting/token-management`} element={<TokenManagement />} />
              <Route
                path={`${process.env.PUBLIC_URL}/setting/daily-withdrawal-limitation`}
                element={<DailyWithdrawalLimitation />}
              />
              <Route path={`${process.env.PUBLIC_URL}/setting/wallet-thresholds`} element={<WalletThresholds />} />
              <Route path={`${process.env.PUBLIC_URL}/wallet-management/wallet-detail`} element={<WalletDetail />} />
              <Route
                path={`${process.env.PUBLIC_URL}/wallet-management/topup-request-history`}
                element={<TopupRequestHistory />}
              />
              <Route path={`${process.env.PUBLIC_URL}/hola-coin`} element={<HolaCoin />} />
            </Route>
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </React.Fragment>
  )
}

export default RouterComponent
