import { toast } from 'react-toastify'

import i18next from 'i18next'

export const toastError = (error: string, toastId?: string) => {
  if (toastId !== undefined) {
    toast.error(i18next.t(error), {
      toastId: 'toastId'
    })
  } else {
    toast.error(i18next.t(error), {
      updateId: "Can't be duplicated again"
    })
  }
}

export const toastSuccess = (success: string) => {
  toast.success(i18next.t(success))
}

export const toastErrorPutMess = (error: string, msg: any) => {
  // toast.error(i18next.t(error, msg ?? {}))
}

export const toastSuccessPutMess = (success: string, msg: any) => {
  // toast.success(i18next.t(success, msg ?? ''))
}

export const toastLoading = (msg: string) => {
  return toast.loading(i18next.t(msg), {
    position: 'bottom-right'
  })
}

export const toastUpdateSuccess = (id: any, msg: string) => {
  toast.update(id, { render: i18next.t(msg), type: 'success', isLoading: false })
}

export const toastUpdateError = (id: any, msg: string) => {
  toast.update(id, { render: i18next.t(msg), type: 'error', isLoading: false })
}

export const toastDismiss = () => {
  toast.dismiss()
}
