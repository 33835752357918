import { LocalStorageKey } from 'constants/localStorageKey'
import { createBrowserHistory } from 'history'
import React, { ReactNode } from 'react'
import { getStorageItem } from 'utils/localStorageHelper'

interface AuthenticationProviderProps {
  children: ReactNode
}
const history = createBrowserHistory()

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = (props) => {
  const { children } = props
  const userToken = getStorageItem(LocalStorageKey.UserToken)
  const pathname = history.location.pathname

  if (userToken === undefined || userToken === null) {
    history.push('/auth/login')
  } else if (
    userToken?.accessToken !== null &&
    (pathname === '/auth/login' || pathname === '/auth/verify-otp' || pathname === '/')
  ) {
    history.push('/dashboard')
  }

  return <>{children}</>
}

export default AuthenticationProvider
