import { createSlice } from '@reduxjs/toolkit'

interface CounterState {
  walletCurrencyList: object[]
}

const initialState = { walletCurrencyList: [] } as CounterState

const walletManagementSlice = createSlice({
  name: 'walletManagement',
  initialState,
  reducers: {
    setWalletCurrency(state, action) {
      state.walletCurrencyList = action.payload
    }
  }
})

export const { setWalletCurrency } = walletManagementSlice.actions
export default walletManagementSlice.reducer
