import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { api } from './base.service'
import walletManagementSlice from './slices/walletManagement.slice'
import { rtkQueryErrorLogger } from 'middleware'
import selectWalletTypeSlice from './slices/selectWalletType.slice'
import inputAmountSlice from './slices/inputAmount.slice'
import selectProductSlice from './slices/selectProduct.slice'
import listSupportedCryptoSlice from './slices/listSupportedCrypto.slice'

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      walletManagementSlice: walletManagementSlice,
      selectWalletTypeSlice: selectWalletTypeSlice,
      selectProductSlice: selectProductSlice,
      inputAmountSlice: inputAmountSlice,
      listSupportedCryptoSlice: listSupportedCryptoSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
    ...options
  })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
