import * as Switcherdata from '../../data/Switcherdata/Switcherdata'

export const changePrimaryColor = (userColor: any) => {
  // var userColor:any = document.getElementById("colorID")
  // userColor.value;
  localStorage.setItem('nowaPrimaryColor', userColor)
  // to store value as opacity 0.95 we use 95
  localStorage.setItem('nowaprimaryHoverColor', '#007349fa')
  localStorage.setItem('nowaprimaryBorderColor', userColor)
  localStorage.setItem('nowaprimaryTransparent', userColor + 20)

  const dynamicPrimaryLight = document.querySelectorAll('input.color-primary-light')

  Switcherdata.dynamicLightPrimaryColor(dynamicPrimaryLight, userColor)

  // Adding
  document.querySelector('body')?.classList.add('light-theme')

  // Removing
  document.querySelector('body')?.classList.remove('dark-theme')
  document.querySelector('body')?.classList.remove('transparent-theme')
  document.querySelector('body')?.classList.remove('bg-img1')
  document.querySelector('body')?.classList.remove('bg-img2')
  document.querySelector('body')?.classList.remove('bg-img3')
  document.querySelector('body')?.classList.remove('bg-img4')

  localStorage.removeItem('nowadarkPrimaryColor')
  localStorage.removeItem('nowatransparentPrimaryColor')
  localStorage.removeItem('nowatransparentBgColor')
  localStorage.removeItem('nowatransparent-bgImgPrimaryColor')
  localStorage.removeItem('nowaBgImage')

  Switcherdata.name()
}
