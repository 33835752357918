import { AnyAction, Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit'
import _ from 'lodash'
import { toastError } from 'utils/toastHelper'

function isPayloadErrorMessage(payload: unknown): payload is {
  data: {
    error: string
  }
  status: number
} {
  return (
    typeof payload === 'object' &&
    payload !== null &&
    'status' in payload &&
    // payload.status === 500)
    !_.includes([200, 401, 412, 201, 400, 404, 403], payload.status)
  )
}

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action: AnyAction) => {
  if (isRejectedWithValue(action)) {
    if (isPayloadErrorMessage(action.payload)) {
      toastError('server_error_label', 'server_error_label')
    }
  }
  return next(action)
}
