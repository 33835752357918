export const ApiReducerPath = 'webReducer'

export const ApiTagTypes = Object.freeze({
  Auth: 'auth',
  Common: 'common',
  Product: 'product',
  Transaction: 'transaction',
  HolaCoin: 'holacoin',
  Client: 'client'
})
