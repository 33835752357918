import { createSlice } from '@reduxjs/toolkit'

interface CounterState {
  selectProduct: any
}

const initialState = { selectProduct: undefined } as CounterState

const selectProductSlice = createSlice({
  name: 'selectProduct',
  initialState,
  reducers: {
    setSelectProduct(state, action) {
      state.selectProduct = action.payload
    }
  }
})

export const { setSelectProduct } = selectProductSlice.actions
export default selectProductSlice.reducer
