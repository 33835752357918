import { createSlice } from '@reduxjs/toolkit'

interface CounterState {
  selectWalletType: any
}

const initialState = { selectWalletType: undefined } as CounterState

const selectWalletTypeSlice = createSlice({
  name: 'selectWalletType',
  initialState,
  reducers: {
    setSelectWalletType(state, action) {
      state.selectWalletType = action.payload
    }
  }
})

export const { setSelectWalletType } = selectWalletTypeSlice.actions
export default selectWalletTypeSlice.reducer
